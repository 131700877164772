import React, { useState, useEffect } from "react";
import '../index.css';

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

const ContactForm = React.forwardRef((props, ref) => {
    const [sent, setSent] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const handleChangeEmail = (e) => setEmail(e.target.value);
    const handleChangeMessage = (e) => setMessage(e.target.value);

    let handleSubmit = async (e) => {
        e.preventDefault();
        var formData = new FormData();
        formData.append('email', email);
        formData.append('message', message);
        try {
            fetch("https://public.herotofu.com/v1/c51071f0-0272-11ed-bc36-e1ea9ccadd33", {
                method: "POST",
                mode: 'no-cors',
                body: formData,
            });
            setSent(true);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        return () => {
            setSent(false);
            setEmail('');
            setMessage('');
        }
    }, []);

    return (
        <div className="popup-box">
            <div className="box" ref={ref}>
                {!sent ?
                    <div>
                        <span className="close-icon" onClick={props.handleClose}>x</span>
                        <div className="input-box">
                            <input
                                className="input-text"
                                value={email}
                                onChange={handleChangeEmail}
                                type="email"
                                placeholder="Your email"
                                name="email"
                                required
                            />
                        </div>
                        <div className="input-box">
                            <textarea
                                className="input-text-multiline"
                                value={message}
                                onChange={handleChangeMessage}
                                placeholder="Your message"
                                name="message"
                                required
                            />
                        </div>
                        <div className="input-box">
                            <button className="button-submit" type="submit" onClick={handleSubmit}>
                                Send
                            </button>
                        </div>
                    </div> :
                    <div>
                        <span className="close-icon" onClick={props.handleClose}>x</span>
                        <div className="input-box">Sent</div>
                    </div>
                }
            </div >
        </div >
    );
})

export default ContactForm;