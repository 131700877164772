export const oviGoalsPerSeasons = {
    s05_06: {
        goals: 52,
        games: 81
    },
    s06_07: {
        goals: 46,
        games: 82
    },
    s07_08: {
        goals: 65,
        games: 82
    },
    s08_09: {
        goals: 56,
        games: 79
    },
    s09_10: {
        goals: 50,
        games: 72
    },
    s10_11: {
        goals: 32,
        games: 79
    },
    s11_12: {
        goals: 38,
        games: 78
    },
    s12_13: {
        goals: 32,
        games: 48
    },
    s13_14: {
        goals: 51,
        games: 78
    },
    s14_15: {
        goals: 53,
        games: 81
    },
    s15_16: {
        goals: 50,
        games: 79
    },
    s16_17: {
        goals: 33,
        games: 82
    },
    s17_18: {
        goals: 49,
        games: 82
    },
    s18_19: {
        goals: 51,
        games: 81
    },
    s19_20: {
        goals: 48,
        games: 68
    },
    s20_21: {
        goals: 24,
        games: 45
    },
    s21_22: {
        goals: 50,
        games: 77
    },
    s22_23: {},
    s23_24: {},
    s24_25: {},
    s25_26: {},
    goals_05_22_per_season_array: [52, 46, 65, 56, 50, 32, 38, 32, 51, 53, 50, 33, 49, 51, 48, 24, 50],
    goals_05_22_accum_array: [52, 98, 163, 219, 269, 301, 339, 371, 422, 475, 525, 558, 607, 658, 706, 730, 780],
    total: {
        goals: 780,
        games: 1274
    }
}

export const gretzkyGoalsPerSeason = {
    s79_80: {
        goals: 51,
        games: 79
    },
    s80_81: {
        goals: 55,
        games: 80
    },
    s81_82: {
        goals: 92,
        games: 80
    },
    s82_83: {
        goals: 71,
        games: 80
    },
    s83_84: {
        goals: 87,
        games: 74
    },
    s84_85: {
        goals: 73,
        games: 80
    },
    s85_86: {
        goals: 52,
        games: 80
    },
    s86_87: {
        goals: 62,
        games: 79
    },
    s87_88: {
        goals: 40,
        games: 64
    },
    s88_89: {
        goals: 54,
        games: 78
    },
    s89_90: {
        goals: 40,
        games: 73
    },
    s90_91: {
        goals: 41,
        games: 78
    },
    s91_92: {
        goals: 31,
        games: 74
    },
    s92_93: {
        goals: 16,
        games: 45
    },
    s93_94: {
        goals: 38,
        games: 81
    },
    s94_95: {
        goals: 11,
        games: 48
    },
    s95_96: {
        goals: 23,
        games: 80
    },
    s96_97: {
        goals: 25,
        games: 82
    },
    s97_98: {
        goals: 23,
        games: 82
    },
    s98_99: {
        goals: 9,
        games: 70
    },
    goals_per_season_array: [51, 55, 92, 71, 87, 73, 52, 62, 40, 54, 40, 41, 31, 16, 38, 11, 23, 25, 23, 9],
    goals_accum_array: [51, 106, 198, 269, 356, 429, 481, 543, 583, 637, 677, 718, 749, 765, 803, 814, 837, 862, 885, 894],
    total: {
        goals: 894,
        games: 1487
    }
}

export const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
