import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function LinePlot(props) {
    const aspect_ratio = props.decrease_ratio ? 1.5 : 2;
    const ticks_font_size = props.smaller_font ? 11 : 14;
    const boxSize = [30, 8]// Default appers to be: [40, 12].
    const options = {
        responsive: true,
        aspectRatio: aspect_ratio,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#FFFFFF',
                    boxWidth: boxSize[0],
                    boxHeight: boxSize[1]
                }
            },
        },
        scales: {
            y: {
                grid: {
                    color: 'rgb(80, 80, 80)'
                },
                ticks: {
                    font:
                    {
                        size: ticks_font_size
                        //weight: 'bold',
                    },
                    color: '#ACACAC',
                }
            },
            x1: {
                position: 'bottom',
                grid: {
                    color: 'rgb(80, 80, 80)'
                },
                ticks: {
                    callback: function (value, index, values) {
                        return this.getLabelForValue(value).split(';')[1];
                    },
                    font: {
                        size: ticks_font_size
                    },
                    color: 'rgb(255, 99, 132)',
                    padding: -1,
                },
            },
            x2: {
                grid: {
                    drawBorder: false, // <-- this removes y-axis line
                    lineWidth: 0.1,
                    color: 'rgb(80, 80, 80)'
                },
                position: 'bottom',
                ticks: {
                    callback: function (value, index, values) {
                        return this.getLabelForValue(value).split(';')[0];
                    },
                    font: {
                        size: ticks_font_size
                    },
                    color: 'rgb(53, 162, 235)',
                    padding: -4,
                },
            }
        }
    };

    const labels = ["79/80;05/06", "80/81;06/07", "81/82;07/08", "82/83;08/09", "83/84;09/10", "84/85;10/11", "85/86;11/12", "86/87;12/13", "87/88;13/14", "88/89;14/15", "89/90;15/16", "90/91;16/17", "91/92;17/18", "92/93;18/19", "93/94;19/20", "94/95;20/21", "95/96;21/22", "96/97;22/23", "97/98;23/24", "98/99;24/25", ";25/26"];

    const data = {
        labels,
        datasets: [
            {
                label: 'Gretzky',
                data: props.gretzky_goals,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                xAxisID: 'x2',
            },
            {
                label: 'Ovechkin',
                data: props.ovi_goals,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                xAxisID: 'x1'
            },
        ],
    };

    return (
        <Line options={options} data={data} />
    )
}