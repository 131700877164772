import React from 'react';
import ReactDOM from 'react-dom/client';
import GoalsToRecord from './components/goals_to_record';
import './index.css';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDGUDjge10vaRtdYne2YT1-1F-S-KwjiiQ",
  authDomain: "ovigoals.firebaseapp.com",
  databaseURL: "https://ovigoals-default-rtdb.firebaseio.com",
  projectId: "ovigoals",
  storageBucket: "ovigoals.appspot.com",
  messagingSenderId: "201757705742",
  appId: "1:201757705742:web:1df0005e1554a377abde5f",
  measurementId: "G-RC9NSZ2673"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoalsToRecord />
);
