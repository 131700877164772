import React, { useState, useEffect, useRef } from 'react';
import { oviGoalsPerSeasons, gretzkyGoalsPerSeason, monthNames, daysOfWeek } from "./constants";
import ContactForm from './contact_form';
import LinePlot from './chart';

// Icons
import Clock from '../public/icons/clock.svg';
import Puck from '../public/icons/puck.svg';

async function oviGoals_22_23() {
    const response = await fetch('https://statsapi.web.nhl.com/api/v1/people/8471214?hydrate=stats(splits=statsSingleSeason)&season=20222023');
    const data = await response.json();
    // If length 0, it means this season is not populated (hasn't started).
    if (data.people[0].stats[0].splits.length > 0) {
        var goals = data.people[0].stats[0].splits[0].stat.goals;
        var games = data.people[0].stats[0].splits[0].stat.games;
        return [goals, games];
    } else {
        return [[], []];
    }
}

async function oviGoals_23_24() {
    const response = await fetch('https://statsapi.web.nhl.com/api/v1/people/8471214?hydrate=stats(splits=statsSingleSeason)&season=20232024');
    const data = await response.json();
    // If length 0, it means this season is not populated (hasn't started).
    if (data.people[0].stats[0].splits.length > 0) {
        var goals = data.people[0].stats[0].splits[0].stat.goals;
        var games = data.people[0].stats[0].splits[0].stat.games;
        return [goals, games];
    } else {
        return [[], []];
    }
}

async function oviGoals_24_25() {
    const response = await fetch('https://statsapi.web.nhl.com/api/v1/people/8471214?hydrate=stats(splits=statsSingleSeason)&season=20242025');
    const data = await response.json();
    // If length 0, it means this season is not populated (hasn't started).
    if (data.people[0].stats[0].splits.length > 0) {
        var goals = data.people[0].stats[0].splits[0].stat.goals;
        var games = data.people[0].stats[0].splits[0].stat.games;
        return [goals, games];
    } else {
        return [[], []];
    }
}

async function oviGoals_25_26() {
    const response = await fetch('https://statsapi.web.nhl.com/api/v1/people/8471214?hydrate=stats(splits=statsSingleSeason)&season=20252026');
    const data = await response.json();
    // If length 0, it means this season is not populated (hasn't started).
    if (data.people[0].stats[0].splits.length > 0) {
        var goals = data.people[0].stats[0].splits[0].stat.goals;
        var games = data.people[0].stats[0].splits[0].stat.games;
        return [goals, games];
    } else {
        return [[], []];
    }
}

async function oviGoals() {
    var ovi_goals = oviGoalsPerSeasons;

    var current_time = new Date();
    var current_year = current_time.getFullYear();
    var current_month = current_time.getMonth(); // Notation: 0-jan, 1-feb etc.
    var current_year_with_month = current_year + current_month / 12;

    if (current_year_with_month >= 2022.666) { // If September started: year + 8/12.
        const [this_season_goals, this_season_games] = await oviGoals_22_23();
        if (!(this_season_goals.length === 0)) {
            ovi_goals.s22_23.goals = this_season_goals;
            ovi_goals.s22_23.games = this_season_games;
            ovi_goals.total.goals += this_season_goals;
            ovi_goals.total.games += this_season_games;
            ovi_goals.goals_05_22_per_season_array.push(this_season_goals);
            ovi_goals.goals_05_22_accum_array.push(ovi_goals.goals_05_22_accum_array[ovi_goals.goals_05_22_accum_array.length - 1] + this_season_goals);
        }
    }
    if (current_year_with_month >= 2023.666) {
        const [this_season_goals, this_season_games] = await oviGoals_23_24();
        if (!(this_season_goals.length === 0)) {
            ovi_goals.s23_24.goals = this_season_goals;
            ovi_goals.s23_24.games = this_season_games;
            ovi_goals.total.goals += this_season_goals;
            ovi_goals.total.games += this_season_games;
            ovi_goals.goals_05_22_per_season_array.push(this_season_goals);
            ovi_goals.goals_05_22_accum_array.push(ovi_goals.goals_05_22_accum_array[ovi_goals.goals_05_22_accum_array.length - 1] + this_season_goals);
        }
    }
    if (current_year_with_month >= 2024.666) {
        const [this_season_goals, this_season_games] = await oviGoals_24_25();
        if (!(this_season_goals.length === 0)) {
            ovi_goals.s24_25.goals = this_season_goals;
            ovi_goals.s24_25.games = this_season_games;
            ovi_goals.total.goals += this_season_goals;
            ovi_goals.total.games += this_season_games;
            ovi_goals.goals_05_22_per_season_array.push(this_season_goals);
            ovi_goals.goals_05_22_accum_array.push(ovi_goals.goals_05_22_accum_array[ovi_goals.goals_05_22_accum_array.length - 1] + this_season_goals);
        }
    }
    if (current_year_with_month >= 2025.666) {
        const [this_season_goals, this_season_games] = await oviGoals_25_26();
        if (!(this_season_goals.length === 0)) {
            ovi_goals.s25_26.goals = this_season_goals;
            ovi_goals.s25_26.games = this_season_games;
            ovi_goals.total.goals += this_season_goals;
            ovi_goals.total.games += this_season_games;
            ovi_goals.goals_05_22_per_season_array.push(this_season_goals);
            ovi_goals.goals_05_22_accum_array.push(ovi_goals.goals_05_22_accum_array[ovi_goals.goals_05_22_accum_array.length - 1] + this_season_goals);
        }
    }
    return ovi_goals;
}

async function capitalsGamesPlayed_22_23() {
    const response = await fetch('https://statsapi.web.nhl.com/api/v1/teams/15?hydrate=stats(splits=statsSingleSeason)&season=20222023');
    const data = await response.json();
    // If there is no 'teams' key, then the season hasn't started yet.
    if (data.hasOwnProperty('teams')) {
        var gamesPlayed = data.teams[0].teamStats[0].splits[0].stat.gamesPlayed;
        return gamesPlayed;
    } else {
        return 0;
    }
}

async function capitalsGamesPlayed_23_24() {
    const response = await fetch('https://statsapi.web.nhl.com/api/v1/teams/15?hydrate=stats(splits=statsSingleSeason)&season=20232024');
    const data = await response.json();
    // If there is no 'teams' key, then the season hasn't started yet.
    if (data.hasOwnProperty('teams')) {
        var gamesPlayed = data.teams[0].teamStats[0].splits[0].stat.gamesPlayed;
        return gamesPlayed;
    } else {
        return 0;
    }
}

async function capitalsGamesPlayed_24_25() {
    const response = await fetch('https://statsapi.web.nhl.com/api/v1/teams/15?hydrate=stats(splits=statsSingleSeason)&season=20242025');
    const data = await response.json();
    // If there is no 'teams' key, then the season hasn't started yet.
    if (data.hasOwnProperty('teams')) {
        var gamesPlayed = data.teams[0].teamStats[0].splits[0].stat.gamesPlayed;
        return gamesPlayed;
    } else {
        return 0;
    }
}

async function capitalsGamesPlayed_25_26() {
    const response = await fetch('https://statsapi.web.nhl.com/api/v1/teams/15?hydrate=stats(splits=statsSingleSeason)&season=20252026');
    const data = await response.json();
    // If there is no 'teams' key, then the season hasn't started yet.
    if (data.hasOwnProperty('teams')) {
        var gamesPlayed = data.teams[0].teamStats[0].splits[0].stat.gamesPlayed;
        return gamesPlayed;
    } else {
        return 0;
    }
}

async function oviGamesLeft_22_26() {
    var current_time = new Date();
    var current_year = current_time.getFullYear();
    var current_month = current_time.getMonth(); // Notation: 0-jan, 1-feb etc.
    var current_year_with_month = current_year + current_month / 12;

    if (current_year_with_month >= 2025.666) {
        const played_games = await capitalsGamesPlayed_25_26();
        const total_games_left = 82 - played_games;
        var games_left = [];
        games_left.total_games_left = total_games_left;
        games_left.s21_22_played = 82;
        games_left.s22_23_played = 82;
        games_left.s23_24_played = 82;
        games_left.s24_25_played = 82;
        games_left.s25_26_played = played_games;
        return games_left;
    }
    if (current_year_with_month >= 2024.666) {
        const played_games = await capitalsGamesPlayed_24_25();
        const total_games_left = 2 * 82 - played_games;
        var games_left = [];
        games_left.total_games_left = total_games_left;
        games_left.s21_22_played = 82;
        games_left.s22_23_played = 82;
        games_left.s23_24_played = 82;
        games_left.s24_25_played = played_games;
        games_left.s25_26_played = 0;
        return games_left;
    }
    if (current_year_with_month >= 2023.666) { // If September started: year + 8/12.
        const played_games = await capitalsGamesPlayed_23_24();
        const total_games_left = 3 * 82 - played_games;
        var games_left = [];
        games_left.total_games_left = total_games_left;
        games_left.s21_22_played = 82;
        games_left.s22_23_played = 82;
        games_left.s23_24_played = played_games;
        games_left.s24_25_played = 0;
        games_left.s25_26_played = 0;
        return games_left;
    }
    // Default case - we are in 22/23 season.
    const played_games = await capitalsGamesPlayed_22_23();
    const total_games_left = 4 * 82 - played_games;
    var games_left = [];
    games_left.total_games_left = total_games_left;
    games_left.s21_22_played = 82;
    games_left.s22_23_played = played_games;
    games_left.s23_24_played = 0;
    games_left.s24_25_played = 0;
    games_left.s25_26_played = 0;
    return games_left;
}

function getCurrentSeasonStats(ovi_goals, ovi_games_left) {
    var stats = [];
    if (Object.keys((ovi_goals.s25_26).length > 0) && (ovi_goals.s25_26.games > 0)) {
        stats.season_name = "2025/2026";
        stats.goals = ovi_goals.s25_26.goals;
        stats.games_played = ovi_goals.s25_26.games;
        stats.goals_per_game = stats.goals / stats.games_played;
        stats.games_left = 82 - ovi_games_left.s25_26_played;
        stats.end_of_season_projection = Math.round(stats.goals + stats.games_left * stats.goals_per_game);
        return stats;
    }
    if (Object.keys((ovi_goals.s24_25).length > 0) && (ovi_goals.s24_25.games > 0)) {
        stats.season_name = "2024/2025";
        stats.goals = ovi_goals.s24_25.goals;
        stats.games_played = ovi_goals.s24_25.games;
        stats.goals_per_game = stats.goals / stats.games_played;
        stats.games_left = 82 - ovi_games_left.s24_25_played;
        stats.end_of_season_projection = Math.round(stats.goals + stats.games_left * stats.goals_per_game);
        return stats;
    }
    if (Object.keys((ovi_goals.s23_24).length > 0) && (ovi_goals.s23_24.games > 0)) {
        stats.season_name = "2023/2024";
        stats.goals = ovi_goals.s23_24.goals;
        stats.games_played = ovi_goals.s23_24.games;
        stats.goals_per_game = stats.goals / stats.games_played;
        stats.games_left = 82 - ovi_games_left.s23_24_played;
        stats.end_of_season_projection = Math.round(stats.goals + stats.games_left * stats.goals_per_game);
        return stats;
    }
    if (Object.keys((ovi_goals.s22_23).length > 0) && (ovi_goals.s22_23.games > 0)) {
        stats.season_name = "2022/2023";
        stats.goals = ovi_goals.s22_23.goals;
        stats.games_played = ovi_goals.s22_23.games;
        stats.goals_per_game = stats.goals / stats.games_played;
        stats.games_left = 82 - ovi_games_left.s22_23_played;
        stats.end_of_season_projection = Math.round(stats.goals + stats.games_left * stats.goals_per_game);
        return stats;
    }
    if (Object.keys((ovi_goals.s21_22).length > 0) && (ovi_goals.s21_22.games > 0)) {
        stats.season_name = "2021/2022";
        stats.goals = ovi_goals.s21_22.goals;
        stats.games_played = ovi_goals.s21_22.games;
        stats.games_left = 82 - ovi_games_left.s21_22_played;
        stats.goals_per_game = stats.goals / stats.games_played;
        stats.end_of_season_projection = Math.round(stats.goals + stats.games_left * stats.goals_per_game);
        return stats;
    }

    return stats;
}

// Returns date and team id for the next regular season game.
async function capitalsNextRegularSeasonGame() {
    const response = await fetch('https://statsapi.web.nhl.com/api/v1/teams/15?expand=team.schedule.next&gameType=R');
    const data = await response.json();

    if (!data.teams[0].hasOwnProperty('nextGameSchedule')) {
        return [[], [], []] // Probably next season is not scheduled yet.
    }
    const date = data.teams[0].nextGameSchedule.dates[0].date;
    const date_with_time = data.teams[0].nextGameSchedule.dates[0].games[0].gameDate;
    const team_home_id = data.teams[0].nextGameSchedule.dates[0].games[0].teams.home.team.id;
    if (team_home_id == 15) { // If home id is capitals then it's another team.
        return [date_with_time, date, data.teams[0].nextGameSchedule.dates[0].games[0].teams.away.team.name];
    } else {
        return [date_with_time, date, data.teams[0].nextGameSchedule.dates[0].games[0].teams.home.team.name];
    }
}

const Card_1 = props => {
    return (
        <div className='card-1'>
            <div className='card-title'>{props.title}</div>
            <div className='card-body'>
                <span>{props.emp}</span> {props.desc}
            </div>
        </div>
    )
}

const Card_2 = props => {
    return (
        <div className='card-2'>
            <div className='card-title'>{props.title}</div>
            <div className='card-body'>
                <span>{props.emp}</span> {props.desc}<br />
                ({props.goals} goals in {props.games} games)
            </div>
        </div>
    )
}

const CurrentSeasonStats = props => {
    const season_not_over = props.seasonStats.games_left > 0;
    return (
        <div>
            {season_not_over ?
                <div className='stats-grid'>
                    < div className='stats-grid' >
                        <Card_1 title='Goals' emp={props.seasonStats.goals} desc={" / total in " + props.seasonStats.games_played + " games"} />
                        <Card_1 title='Goal Pace' emp={props.seasonStats.goals_per_game.toLocaleString(undefined, { maximumFractionDigits: 3 })} desc=" / goals per game" />
                    </div >
                    <div className='stats-grid'>
                        <Card_1 title='Games Left' emp={props.seasonStats.games_left} desc=" / total" />
                        <Card_1 title='End of Season Projection' emp={props.seasonStats.end_of_season_projection} desc=' / goals' />
                    </div>
                </div >
                :
                <div className='stats-grid'>
                    < div className='stats-grid' >
                        <Card_1 title='Goals' emp={props.seasonStats.goals} desc={" / total"} />
                        <Card_1 title='Games Played' emp={props.seasonStats.games_played} desc=" / total" />
                    </div >
                    <div className='stats-grid'>
                        <Card_1 title='Goal Pace' emp={props.seasonStats.goals_per_game.toLocaleString(undefined, { maximumFractionDigits: 3 })} desc=" / goals per game" />
                        <Card_1 title='Games Left' emp="0" desc=' / season is over' />
                    </div>
                </div >
            }
        </div>
    )
}

// Parse a date in yyyy-mm-dd format
function parseDate(input) {
    var parts = input.match(/(\d+)/g);
    // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
    return new Date(parts[0], parts[1] - 1, parts[2]); // months are 0-based
}

export default function GoalsToRecord() {
    const [loading, setLoading] = useState(true);
    const [oviTotalGoals, setOviTotalGoals] = useState(-1);
    const [oviTotalGamesPlayed, setOviTotalGamesPlayed] = useState(-1);
    const [oviGamesLeft, setOviGamesLeft] = useState(-1);
    const [oviGoalsPerSeasonArray, setOviGoalsPerSeasonArray] = useState([]);
    const [oviGoalsPerSeasonArrayAccum, setOviGoalsPerSeasonArrayAccum] = useState([]);
    const [currentSeasonStats, setCurrentSeasonStats] = useState([])
    const [nextGame, setNextGame] = useState([]);

    const [verticalScreen, setVerticalScreen] = useState(false);
    const [smallScreen, setSmallScreen] = useState(false);
    const [tinyScreen, setTinyScreen] = useState(false);

    const [formIsOpen, setFormIsOpen] = useState(false);

    const catMenu = useRef(null)

    const openPopup = () => {
        setFormIsOpen(true);
    }

    const closePopup = () => {
        setFormIsOpen(false);
    }
    const closeOpenMenus = (e) => {
        if (catMenu.current && formIsOpen && !catMenu.current.contains(e.target)) {
            setFormIsOpen(false);
        }
    }

    document.addEventListener('mousedown', closeOpenMenus)

    async function readData() {
        const ovi_goals = await oviGoals();
        setOviTotalGoals(ovi_goals.total.goals);
        setOviTotalGamesPlayed(ovi_goals.total.games);
        setOviGoalsPerSeasonArray(ovi_goals.goals_05_22_per_season_array);
        setOviGoalsPerSeasonArrayAccum(ovi_goals.goals_05_22_accum_array);
        const ovi_games_left = await oviGamesLeft_22_26();
        setOviGamesLeft(ovi_games_left.total_games_left);

        // Get stats of the current season.
        setCurrentSeasonStats(getCurrentSeasonStats(ovi_goals, ovi_games_left));

        // Get the next game.
        const [next_game_date_with_time, next_game_date, next_game_team] = await capitalsNextRegularSeasonGame();
        if (!(next_game_date.length === 0)) {
            var next_game = [];

            const parsed_date = parseDate(next_game_date);
            const date_with_time = new Date(next_game_date_with_time); // In UTC, so it's safe to only use for time (date may be off).

            const hour_24 = date_with_time.toLocaleTimeString('en-US', { hour: '2-digit', hour12: false, timeZone: 'America/New_York' });
            const hour_pm = hour_24 > 12 ? (hour_24 - 12) : hour_24// Assuming all games start pm ET.

            next_game.team = next_game_team;
            next_game.day_of_month = parsed_date.getDate();
            next_game.month = monthNames[parsed_date.getMonth()];
            next_game.day_of_week = daysOfWeek[parsed_date.getDay()];
            next_game.hour_new_york = hour_pm;
            setNextGame(next_game);
        }

        if (window.innerHeight > window.innerWidth) {
            setVerticalScreen(true);
        }
        if (window.innerWidth < 1100) {
            setSmallScreen(true);
        }
        if (window.innerWidth < 800) {
            setTinyScreen(true);
        }

        setLoading(false);
    }

    // Runs right after initial render.
    useEffect(() => {
        if (window.location.hostname == "ovigoals.web.app" ||
            window.location.hostname == "ovigoals.firebaseapp.com") {
            window.location.href = 'https://willovechkinpassgretzky.com';
        }
        setLoading(true);
        readData();
    }, []);

    const oviGoalsPerGameSoFar = oviTotalGoals / oviTotalGamesPlayed;
    const oviGoalsPerGameToBeat = (gretzkyGoalsPerSeason.total.goals - oviTotalGoals + 1) / oviGamesLeft;

    return (
        <div>
            {loading ? <div></div> :

                <div className='main-wrapper' style={{ padding: (verticalScreen || smallScreen) ? '0 2rem' : '0 20rem' }} >
                    {/* banner container */}
                    <div className='cover-container'>
                        <div className='section-1'>
                            <div className='header'>Yes, Ovechkin is on track to beat the Gretzky's goal record!</div>
                            <div className='desc'>He has to score {gretzkyGoalsPerSeason.total.goals - oviTotalGoals + 1} goals in {oviGamesLeft} games*.</div>
                        </div>
                    </div>

                    {/* Next Game*/}
                    {Object.keys(nextGame).length === 0 ? <div></div> :
                        <div className='stats-wrapper'>
                            <div className='header'>Next Ovi's Game</div>
                            <div className='stats-next-game'>{nextGame.month} {nextGame.day_of_month} ({nextGame.day_of_week}) {nextGame.hour_new_york}pm ET vs {nextGame.team}</div>
                        </div>
                    }

                    {/* Current season stats */}
                    <div className='stats-wrapper'>
                        <div className='header'>{currentSeasonStats.season_name} Season Ovechkin Stats</div>
                        <CurrentSeasonStats seasonStats={currentSeasonStats} />
                    </div>

                    {/* Ovi vs Gretzky comparison */}
                    <div className='stats-wrapper'>
                        <div className='header'>Ovechkin vs Gretzky Career Comparison</div>
                        <div className='stats-grid'>
                            <div className='stats-grid'>
                                <Card_1 title='Ovechkin Goals' emp={oviTotalGoals} desc=' / total' />
                                <Card_1 title='Gretzky Goals' emp={gretzkyGoalsPerSeason.total.goals} desc=" / total" />
                            </div>
                            <div className='stats-grid'>
                                <Card_2 title='Pace To Beat The Record' emp={oviGoalsPerGameToBeat.toLocaleString(undefined, { maximumFractionDigits: 3 })} goals={gretzkyGoalsPerSeason.total.goals - oviTotalGoals + 1} games={oviGamesLeft} desc=" / goals per game" />
                                <Card_2 title='Ovi Career Pace So Far' emp={oviGoalsPerGameSoFar.toLocaleString(undefined, { maximumFractionDigits: 3 })} goals={oviTotalGoals} games={oviTotalGamesPlayed} desc=" / goals per game" />
                            </div>
                        </div>
                    </div>

                    {/* Chart */}
                    <div className='charts-wrapper'>
                        <div className='chart-contianer'>
                            <div className='header'>Goals per season</div>
                            <LinePlot ovi_goals={oviGoalsPerSeasonArray} gretzky_goals={gretzkyGoalsPerSeason.goals_per_season_array} decrease_ratio={smallScreen} smaller_font={tinyScreen} />
                        </div>
                        <div className='chart-contianer'>
                            <div className='header'>Total goals progression</div>
                            <LinePlot ovi_goals={oviGoalsPerSeasonArrayAccum} gretzky_goals={gretzkyGoalsPerSeason.goals_accum_array} decrease_ratio={smallScreen} smaller_font={tinyScreen} />
                        </div>
                    </div>

                    {/*Bottom Container*/}
                    <div className='bottom-container'>
                        <ul>
                            <li> <span><img src={Puck} /></span> <span>*Ovi is chasing the regular season goal record: playoff goals are not considered.</span> </li>
                        </ul>
                        <ul>
                            <li> <span><img src={Clock} /></span> <span>*Ovi is likely to retire at the end of the 25/26 season, when his current contract with the Washington Capitals expires: he will be 40 years 10 months old.</span></li>
                        </ul>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ marginBottom: '2rem', color: '#ACACAC', display: 'inline-block' }} onClick={openPopup}>contact us</div>
                    </div>
                    {formIsOpen && <ContactForm handleClose={closePopup} ref={catMenu} />
                    }
                </div>
            }
        </div >
    )
}